import { LocalStorage } from "quasar";

const routes = [
  {
    path: "/",
    component: () => import("layouts/SimpleLayout.vue"),
    children: [
      {
        path: "",
        beforeEnter: (to, from, next) => {
          const user = LocalStorage.getItem("user");

          if (user !== null) {
            next({ path: "/dashboard" });
            return;
          }

          next();
        },
        component: () => import("pages/Login.vue"),
      },
      {
        path: "/invited/:partnerId",
        component: () => import("src/pages/Register.vue"),
      },
    ],
  },
  {
    path: "/remember_password",
    beforeEnter: (to, from, next) => next({ path: "/" }),
    component: () => import("layouts/SimpleLayout.vue"),
    props: { backButton: true },
    children: [
      {
        path: "",
        component: () => import("pages/RememberPassword.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("layouts/MainLayout.vue"),
    props: { bigHeader: true },
    children: [
      {
        path: "",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/Index.vue"),
      },
    ],
  },
  {
    path: "/stores",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/Stores.vue"),
      },
    ],
  },

  {
    path: "/bank_statements",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/BankStatements.vue"),
      },
    ],
  },
  {
    path: "/restricted",
    component: () => import("layouts/MainLayout.vue"),
    meta: {
      requiresRestrictedArea: true,
    },
    children: [
      {
        path: "network/install",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/restricted/NetworkInstall.vue"),
      },
      {
        path: "bank_statements/monthly_payment",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("pages/restricted/BankStatementsMonthlyPayment.vue"),
      },
      {
        path: "bank_statements/participation",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("pages/restricted/BankStatementsParticipation.vue"),
      },
    ],
  },
  {
    path: "/profile",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/Profile.vue"),
      },
      {
        path: "update",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/UpdateProfile.vue"),
      },
      {
        path: "change_password",
        meta: {
          requiresAuth: true,
        },
        component: () => import("pages/UpdatePassword.vue"),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
];

export default routes;
