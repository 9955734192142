import { LocalStorage, Notify, date } from "quasar";
import router from "../router";

export const TYPE_VII = 1;
export const TYPE_BONDS = 2;
export const TYPE_SELLING = 3;
export const TYPE_BUYING = 4;

export const INCOMPLETE_PROFILE = "INCOMPLETE_PROFILE";
export const INCOMPLETE_ADDRESS = "INCOMPLETE_ADDRESS";

export const REMEMBER_PASSWORD_REQUEST_CODE = "REMEMBER_PASSWORD_REQUEST_CODE";
export const REMEMBER_PASSWORD_CHANGE_PASSWORD =
  "REMEMBER_PASSWORD_CHANGE_PASSWORD";

export const BANK_STATEMENTS_RANGE_DEFAULT = 7;
export const BANK_STATEMENTS_RANGE = [
  {
    value: BANK_STATEMENTS_RANGE_DEFAULT,
    label: `${BANK_STATEMENTS_RANGE_DEFAULT} dias`,
  },
  {
    value: 15,
    label: "15 dias",
  },
  {
    value: 30,
    label: "30 dias",
  },
  {
    value: null,
    label: "Outras Datas",
  },
];

export const GENDERS = [
  {
    id: "M",
    name: "Masculino",
  },
  {
    id: "F",
    name: "Feminino",
  },
];

export const STATES = [
  { name: "Acre", id: "AC" },
  { name: "Alagoas", id: "AL" },
  { name: "Amapá", id: "AP" },
  { name: "Amazonas", id: "AM" },
  { name: "Bahia", id: "BA" },
  { name: "Ceará", id: "CE" },
  { name: "Distrito Federal", id: "DF" },
  { name: "Espírito Santo", id: "ES" },
  { name: "Goiás", id: "GO" },
  { name: "Maranhão", id: "MA" },
  { name: "Mato Grosso", id: "MT" },
  { name: "Mato Grosso do Sul", id: "MS" },
  { name: "Minas Gerais", id: "MG" },
  { name: "Pará", id: "PA" },
  { name: "Paraíba", id: "PB" },
  { name: "Paraná", id: "PR" },
  { name: "Pernambuco", id: "PE" },
  { name: "Piauí", id: "PI" },
  { name: "Rio de Janeiro", id: "RJ" },
  { name: "Rio Grande do Norte", id: "RN" },
  { name: "Rio Grande do Sul", id: "RS" },
  { name: "Rondônia", id: "RO" },
  { name: "Roraima", id: "RR" },
  { name: "Santa Catarina", id: "SC" },
  { name: "São Paulo", id: "SP" },
  { name: "Sergipe", id: "SE" },
  { name: "Tocantins", id: "TO" },
];

export const PAGINATION_PER_PAGE_OPTIONS = [15, 30, 60, 120];

export const BANK_ACCOUNT_TYPES = [
  {
    id: "1",
    name: "Corrente",
  },
  {
    id: "2",
    name: "Poupança",
  },
];

export const LOCALES = {
  days: [
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
    "Domingo",
  ],
  daysShort: ["SEG", "TER", "QUA", "QUI", "SEX", "SAB", "DOM"],
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  monthsShort: [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ],
};

export const PARCEIRO_NEGOCIO_TYPES = [
  {
    label: "Parceiro de Negócios",
    value: 1,
  },
  {
    label: "Motorista",
    value: 2,
  },
  {
    label: "Profissional Independente",
    value: 3,
  },
];

export const formatBrazilianCurrency = (value) => {
  const formated = String(value || 0).replace(",", ".");

  return Number(formated).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const logout = () => {
  LocalStorage.remove("current_token");
  LocalStorage.remove("authentication");
  LocalStorage.remove("user");

  return { path: "/" };
};

export const generateInviteLink = () => {
  const { partnerId } = LocalStorage.getItem("user");
  return new URL(
    router().resolve({ path: `/invited/${partnerId}` }).href,
    window.location.href
  ).href;
};

export const generateInviteLinkWithMessage = (message) => {
  const link = generateInviteLink();

  return `${message} *${link}*`;
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });
};

export const formatDate = (
  curretDate,
  hour,
  format = "DD/MM/YYYY HH:mm:ss"
) => {
  const dateObject = new Date(`${curretDate} ${hour}`);

  return date.formatDate(dateObject, format);
};

export const getPaginationLabel = (
  firstRowIndex,
  endRowIndex,
  totalRowsNumber
) => `${firstRowIndex}-${endRowIndex} de ${totalRowsNumber}`;

export const isValidJson = (text) => {
  try {
    if (typeof text === "object") {
      return true;
    }

    JSON.parse(text);
  } catch (error) {
    if (process.env.IS_DEVELOP) {
      console.error(error);
    }

    return false;
  }

  return true;
};

export const encryptMyNetworkUrl = (partnerId) => `${partnerId * 7 + 777777}`;

export const hasAccessRestrictedArea = () => {
  const user = LocalStorage.getItem("user");

  const isGestor = user.gestor ?? false;
  const isCallcenter = user.callcenter ?? false;
  const isVendedor = user.vendedor ?? false;

  if (process.env.ENABLED_ONLY_GESTOR_ACCESS_RESTRICTED) {
    return isGestor === true;
  }

  // Forma antiga para validar se usuário pode acessar a area restrita
  return isGestor === true || isCallcenter === true || isVendedor === true;
};

export const isProfileIncompleted = ({ message }) => {
  const messages = [INCOMPLETE_PROFILE, INCOMPLETE_ADDRESS];

  return messages.includes(message);
};

export const redirectAndNotifyProfileIncompleted = () => {
  Notify.create({
    type: "negative",
    message:
      "Perfil incompleto, você será redirecionado para a página para completa-lo.",
  });

  return { path: "/profile/update" };
};

export const generateRandomNumber = (min = 1000, max = 9999) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
};
