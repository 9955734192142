import { boot } from "quasar/wrappers";
import { ref } from "vue";

export default boot(({ store }) => {
  window.deferredPrompt = ref(null);

  window.addEventListener("beforeinstallprompt", (event) => {
    window.deferredPrompt.value = event;

    event.userChoice.then(({ outcome }) => {
      store.dispatch("main/setAppInstalled", !!outcome);

      window.deferredPrompt.value = null;
    });
  });

  window.addEventListener("appinstalled", () => {
    store.dispatch("main/setAppInstalled", true);

    window.deferredPrompt.value = null;
  });
});
