import { LocalStorage } from "quasar";

export const updateShowBalanceState = (state, value) => {
  LocalStorage.set("show_balances", value);

  state.showBalances = value;
};

export const updateAppInstalled = (state, appInstalled) => {
  state.appInstalled = appInstalled;
};
