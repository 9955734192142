<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue";
import { useMeta } from "quasar";

export default defineComponent({
  name: "App",
  setup() {
    const appName = process.env.APP_NAME;
    const description =
      "A VESPERS é uma empresa formada com a finalidade de revolucionar a Distribuição de Renda, primeiro no Brasil e depois no Mundo.";

    useMeta({
      titleTemplate: (title) => (title ? `${title} - ${appName}` : appName),
      meta: {
        description: {
          name: "description",
          content: description,
        },
        keywords: {
          name: "keywords",
          content:
            "Acompanhe em seus extratos os ganhos financeiros e em breve você receberá uma ligação de nossos Gestores de como Sacar o Dinheiro Distribuído. Você receberá uma mensagem Especial, quinzenalmente ou mensalmente para participar de Um Sorteio, Ganhar um Cupom ou Uma Raspadinha.",
        },
        equiv: {
          "http-equiv": "Content-Type",
          content: "text/html; charset=UTF-8",
        },
        ogTitle: {
          property: "og:title",
          template(ogTitle) {
            return ogTitle ? `${ogTitle} - ${appName}` : appName;
          },
        },
        ogDescription: {
          property: "og:description",
          template() {
            return description;
          },
        },
        ogImage: {
          property: "og:image",
          template() {
            return new URL("icons/icon.png", window.location.href).href;
          },
        },
      },
    });
  },
});
</script>
