import { boot } from "quasar/wrappers";
import {
  logout,
  isValidJson,
  INCOMPLETE_PROFILE,
  INCOMPLETE_ADDRESS,
} from "./consts";
import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.API_BASE_URL}/api/mobile.php`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const clientDefault = axios.create({
  baseURL: `${process.env.API_BASE_URL}/api/mobile.php`,
});

const appendData = (data) => new URLSearchParams({ ...data });

client.interceptors.request.use(
  (config) => {
    const data = appendData(config.data);

    return {
      ...config,
      data,
    };
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => {
    if (response.data !== null) {
      if (response.data.result === process.env.ERROR_AUTH_MESSAGE) {
        const { path } = logout();

        window.location.href = path;

        throw new Error(process.env.ERROR_AUTH_MESSAGE);
      }

      if (response.data.result === process.env.ERROR_INCOMPLETE_MESSAGE) {
        throw new Error(INCOMPLETE_PROFILE);
      }

      if (response.data.result === process.env.ERROR_ADDRESS_MESSAGE) {
        throw new Error(INCOMPLETE_ADDRESS);
      }
    }

    if (isValidJson(response.data) === false) {
      throw new Error(process.env.ERROR_INVALID_RESPONSE);
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = client;
});

export { axios, client, clientDefault };
