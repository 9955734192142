import { LocalStorage } from "quasar";
import { reactive } from "vue";

export default () => {
  const showBalances = LocalStorage.getItem("show_balances") ?? true;
  const appInstalled =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone === true;

  return reactive({
    showBalances,
    appInstalled,
  });
};
